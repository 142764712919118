
import GwContentTemplate from '~/patterns/templates/content.vue';
import getCoursePages from '~/graphql/queries/getCoursePages.graphql';
import seo from '~/mixins/seo';
import getCoursesPage from '~/graphql/queries/getCoursesPage.graphql';
import GwPageTitle from '~/patterns/molecules/page-title/page-title';
import GwPageContainer from '~/patterns/molecules/page-container/page-container';
import FlexibleSection from '~/patterns/organisms/_flexible-section/flexible-section';
import GwPageIntro from '~/patterns/molecules/page-intro/page-intro';
import GwProgramList from '~/patterns/organisms/program-list/program-list';
import GwError from '~/patterns/molecules/error';

export default {
    components: {
        GwContentTemplate,
        GwPageContainer,
        GwError,
        GwPageTitle,
        GwPageIntro,
        FlexibleSection,
        GwProgramList,
    },
    mixins: [seo],
    layout: 'overview',
    data() {
        return {
            entry: null,
            courses: null,
            notFound: null,
        };
    },
    async fetch() {
        await Promise.all([
            this.getPage(),
            this.getCourses()
        ]);
    },
    methods: {
        async getPage() {
            const site = this.$config.appMultisiteHandle,
                uri = this.$route.path.replace(/^\/|\/$/, ''),
                { data } = await this.$gql.executeQuery(
                    getCoursesPage,
                    { site, uri }
                );

            if (data && data.entry) {
                this.entry = data.entry;
            } else {
                this.notFound = true;
                if (process.server) {
                    this.$nuxt.context.res.statusCode = 404;
                }
            }
        },
        async getCourses() {
            const coursePages = await this.$gql.executeQuery(
                getCoursePages,
                { site: this.$config.appMultisiteHandle }
            );

            this.courses = coursePages.data.entries;
        }
    },
    nuxtI18n: {
        paths: {
            en: '/courses',
            in: '/courses',
            nl: '/trainingen',
            fr: '/formations',
            de: '/trainings',
            'en-Highberg': '/courses',
            'nl-Highberg': '/trainingen',
            'de-Highberg': '/trainings',
        }
    }
};
