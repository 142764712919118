
import GwProgramCard from '~/patterns/atoms/card/presets/program-card';
import GwCourseList from '~/patterns/organisms/course-list/course-list';

export default {
    components: {
        GwCourseList,
        GwProgramCard,
    },
    props: {
        courses: {
            type: Array,
            default: () => { return []; },
        }
    },
    computed: {
        programs() {
            return this.courses.reduce(function(result, course) {
                const key = course.programs?.[0]?.id;

                if (!result[key]) {
                    result[key] = {
                        program: course.programs[0],
                        courses: []
                    };
                }
                result[key].courses.push(course);

                return result;
            }, {});
        }
    }
};
